import axios from "axios";
import { marketplaceUrl } from "../../utility/env";
import { filters } from "./types";

const api = axios.create({
  baseURL: marketplaceUrl,
});
// const config = {
//     headers: {
//       Authorization: localStorage.getItem("refreshToken"),
//     },
//   };

export async function fetchShopByUser(name: string | string[] | undefined, city: string) {
  const res = await api.get(`/shop/one/${name}`);
  return res;
}

export async function fetchIndependentFilter(shopId: number | number[] | undefined, filters: any) {
  let paramss = new URLSearchParams(filters);
  //
  paramss.delete("page");
  let request = {
    params: paramss,
  };
  const res = await api.get(`/listings/filters/shop/${shopId}`, request);

  return res;
}
export async function fetchdependentFilter(shopId: number | number[] | undefined, categoryId: number | number[] | undefined, dParams: any) {
  let params = new URLSearchParams(dParams);
  params.delete("category_id");
  params.delete("page");
  let request = {
    params: params,
  };
  const res = await api.get(`/listings/filters/shop/${shopId}/c/${categoryId}`, request);

  return res;
}
export async function fetchItems(filters: filters, currentPage: number, size: number, shopId: number, dParams: any) {
  let params = new URLSearchParams(dParams);

  let request = {
    params: dParams,
  };

  const res = await api.get(`shop/${shopId}/items?size=${size}`, request);

  return res;
}
export async function fetchItemDetails(id: string) {
  const res = await api.get(`/listings/detail/${id}`);
  return res;
}
export async function fetchRelatedItems(id: string) {
  const res = await api.get(`Listings/related-items/${id}`);
  return res;
}
export async function fetchGoogleUser(token: any) {
  const res = await api.get(`/auth/google/${token}`);
  return res;
}
export async function fetchFacebookUser(token: any) {
  const res = await api.get(`/auth/facebook/${token}`);
  return res;
}

export async function fetchPLPListing(currentPage: number, size: number, lparam: any) {
  let params = new URLSearchParams(lparam);
  // lparam.delete("page")

  let request = {
    params: lparam,
  };
  const res = await api.get(`/listings?size=${size ? size : ""}`, request);
  return res;
}

export async function fetchPLPListingForSitemap(currentPage: number, size: number, category_id:number) {
  let uri;

  uri = `/listings?&size=${size ? size : ""}&page=${currentPage}&category_id=${category_id}`;

  const res = await api.get(uri, {});
  return res;
}

export async function fetchIndependentFilterListing(filters: any) {
  let paramss = new URLSearchParams(filters);

  paramss.delete("page");
  let request = {
    params: paramss,
  };

  const res = await api.get(`/listings/filters`, request);

  return res;
}
export async function fetchdependentFilterListing(categoryId: number | number[] | undefined, fParam: any) {
  let params = new URLSearchParams(fParam);
  params.delete("category_id");
  params.delete("sort");
  params.delete("page");

  let request = {
    params: params,
  };

  const res = await api.get(`/listings/filters/c/${categoryId}`, request);

  return res;
}

export async function fetchRecommendedListing(count: number, categoryId: string, location: string) {
  const res = await api.get(`/listings/random?count=${count}&category=${categoryId}&city=${location}`);
  return res;
}

export async function fetchTopSeller() {
  const res = await api.get(`/shop/all?criteria=most-listings&page=${1}&size=${20}`);

  return res;
}

export async function getUserByToken(token: string) {
  const res = await api.get(`/auth/get-user`, {
    headers: {
      Authorization: token,
    },
  });
  return res;
}

export async function refreshToken(token: string) {
  const res = await api.get(`/auth/refresh-token/`, {
    headers: {
      Authorization: token,
    },
  });
  return res;
}

export async function getUserWishlist(token: string, id: string, currentPage?: number, sizeOfPage?: number) {
  if (currentPage && sizeOfPage) {
    const res = await api.get(`/user-wishlist/${id}?page=${currentPage}&size=${sizeOfPage}`, {
      headers: {
        Authorization: token,
      },
    });
    return res;
  } else {
    const res = await api.get(`/user-wishlist/${id}?returnIds=1`, {
      headers: {
        Authorization: token,
      },
    });
    return res;
  }
}

// Navbar methods
export async function getCity() {
  const res = await api.get(`/city`);
  return res;
}
export async function getCondition() {
  const res = await api.get(`/condition`);
  return res;
}
export async function getNavMenuData() {
  const res = await api.get(`/home/nav-categories`);
  return res;
}

export async function getSearchSuggestions(search: string) {
  const res = await api.get(`/listings/search-suggestions?search=${search}`);
  return res;
}

export async function getReservations(token: string, status: string) {
  const res = await api.get(`/reservation?status=${status}`, {
    headers: {
      Authorization: token,
    },
  });
  return res;
}

export async function fetchModels(page: number, size: number, category_id: number, fParam: any) {
  let params = new URLSearchParams(fParam);

  let request = {
    params: params,
  };
  let uri;
  if (category_id === 6 && params.get("at_id") !== null) {
    // Fix the typo here, remove the extra '?'
    uri = `/model/${category_id}/all?${encodeURIComponent(`at_id=${params.get("at_id")}&size=${size}`)}`;
  } else {
    uri = `/model/${category_id}/all?size=${size}`;
  }

  const res = await api.get(uri, request);
  return res;
}
export async function fetchModelsForSitemap(page: number, size: number, category_id: number, fParam?: any) {
  let uri;
  uri = `/model/${category_id}/all?page=${page}&size=${size}`;

  const res = await api.get(uri, {});
  return res;
}

export async function fetchAccessoriesType() {
  const res = await api.get(`/model/accessory-types`);
  return res;
}

export async function fetchModelFilter(category_id: number, fParam: any) {
  let params = new URLSearchParams(fParam);
  params.delete("sort");
  params.delete("page");

  let request = {
    params: params.size === 0 ? "" : params,
  };
  const res = await api.get(`/model/filters/${category_id}`, request);
  return res;
}

export async function fetchItem(model_id: number) {
  const res = await api.get(`/model/${model_id}`);
  return res;
}

export async function fetchRecommendedModels(model_id: number) {
  const res = await api.get(`/model/${model_id}/related-models?page=1&size=10&criteria=mix`);
  return res;
}
export async function fetchIsListingAvailableForModel(model_id: number) {
  const res = await api.get(`/model/${model_id}/related-listing`);
  return res;
}

export async function fetchAllStores(page: number, size: number, search: string, category: number, city: number) {
  let paramss = new URLSearchParams();
  paramss.append("search", search ? search : "");
  paramss.append("category", String(category ?? null));
  paramss.append("city", String(city ? city : ""));
  paramss.append("page", String(page));
  paramss.append("size", String(size));

  let request = {
    params: paramss,
  };

  const res = await api.get(`/shop/all`, request);
  return res;
}

export async function fetchSeeMoreFilters(filter: any, params: any) {
  const res = await api.get(`/listings/filters/more?query=${filter}&${params}`);
  return res;
}
export async function fetchSeeMoreDependentFilters(id?: number, query?: any, params?: any) {
  let paramss = new URLSearchParams(params);
  paramss.delete("category_id");
  const res = await api.get(`/listings/filters/c/${id}/more?query=${query}&${paramss}`);
  return res;
}

export async function fetchSeeMoreFiltersForShop(filter: any, params: any, shopId: number) {
  const res = await api.get(`/listings/filters/shop/${shopId}/more?query=${filter}&${params}`);
  return res;
}
export async function fetchSeeMoreDependentFiltersForShop(id: number | undefined, query: any, params: any, shopId: number) {
  let paramss = new URLSearchParams(params);
  paramss.delete("category_id");
  const res = await api.get(`/listings/filters/shop/${shopId}/c/${id}/more?query=${query}&${paramss}`);
  return res;
}

export async function fetchSeeMoreDependentFiltersForSpecs(id: number, query: any, params: any) {
  let fparams = new URLSearchParams(params);
  fparams.delete("page");

  let request = {
    params: fparams.size === 0 ? "" : fparams,
  };

  const res = await api.get(`/model/filters/${id}/more?query=${query}&${query}=[${id}]`, request);
  return res;
}
export async function fetchVariantsForModel(id: number, name: string) {
  const res = await api.get(`/model/${id}/variants?name=${encodeURIComponent(name)}`);
  return res;
}

export async function verifySellerUser(name: string | string[], token: any) {
  const res = await api.get(`/shop/${name}/verify?token=${token}`);
  return res;
}

export async function FetchMostViewed(size: number, location: string) {
  const res = await api.get(`/listings/most-viewed?count=${size}&city=${location}`);

  return res;
}

export async function GetBrands() {
  const res = await api.get(`/home/top-10-listed-brands`);
  return res;
}

export async function getCities() {
  const res = await api.get(`/city`);
  return res;
}

export async function getDeliveryCharges(token: string) {
  const res = await api.get(`/delivery-charges`, {
    headers: {
      Authorization: token,
    },
  });
  return res;
}

export async function FetchOrder(token: string, page?: number, size?: number, from?: string | null, to?: string | null, status?: string, orderBy?: Record<string, string>, shop_id?: number) {
  const params: string[] = [];

  const addQueryParam = (key: string, value: any) => {
    if (value !== null && value !== undefined && value !== "") {
      params.push(`${key}=${encodeURIComponent(value)}`);
    }
  };

  // Add query parameters if they exist
  addQueryParam("page", page);
  addQueryParam("size", size);
  addQueryParam("fk_shop_id", shop_id);
  addQueryParam("status", status);

  if (orderBy && Object.keys(orderBy).length > 0) {
    const sortValue = `${Object.keys(orderBy)[0]}:${Object.values(orderBy)[0]}`;
    addQueryParam("sort", sortValue);
  }

  // Optional date range filters
  addQueryParam("from", from);
  addQueryParam("to", to);

  const queryString = params.length ? `?${params.join("&")}` : "";
  const url = `delivery-order${queryString}`;
  // API call
  const res = await api.get(url, {
    headers: {
      Authorization: token,
    },
  });

  return res;
}

export async function fetchOrderByOrderNo(orderNo: string | string[]) {
  const res = await api.get(`delivery-order/${orderNo}`);
  return res;
}

export async function StoreFilters() {
  const res = await api.get(`/shop/filters`);
  return res;
}
export async function ApplyVoucherCode(VoucherCode: string) {
  const res = await api.get(`/delivery-for-you-voucher/${VoucherCode}`);
  return res;
}

export async function getBannesByPage(page: string) {
  const res = await api.get(`/marketplace-banners/${page}`);
  return res;
}

export async function getFeaturedListings(id: number, place: string,) {
  
  let paramss = new URLSearchParams();

//  paramss.append("category", String(id));
  paramss.append("place", place);
  paramss.append("count", '20');
  id&&paramss.append('category',String(id))

  let request = {
    params: paramss,
  };

  
  const res = await api.get(`/listings/featured`, request);
  return res;
}

export async function getTopdiscountedListings(city: string) {
  let paramss = new URLSearchParams();

  paramss.append("city", city);
  let request = {
    params: paramss,
  };

  const res = await api.get(`/listings/top-discounted`, request);
  return res;
}
